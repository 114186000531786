import { RightOutlined } from '@ant-design/icons';
import { OrderDoc, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderUI } from 'src/schema/schema-order-ui';

import { orderStatusEn, orderStatusKr } from '../../../lib/1/string-map';
import { formatNumber } from '../../../lib/1/util';

import classes from './OrderHistoryItem.module.scss';

type OrderHistoryItemProps = {
  order: OrderDoc & OrderUI;
};

const OrderHistoryItem: FC<OrderHistoryItemProps> = ({ order }) => {
  const navigate = useNavigate();

  const orderDate = format(new Date(order.orderDate), 'L월 d일 (EEEEEE) HH:mm:ss', { locale: ko });
  const productAmount = order.products.reduce((acc, p) => (acc += p.volume), 0);

  const storeIssues = order._ui?.storeIssues;
  const queryParams = storeIssues ? '?' + storeIssues?.map((issue) => `issue-id=${issue._id}`).join('&') : '';
  const issuesTotalAmount = storeIssues
    ? storeIssues.reduce((acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0)) * (cur.volume ?? 1), 0)
    : 0;

  return (
    <div className={classes.historyItemContainer} onClick={() => navigate(`/order-detail/${order._id}${queryParams}`)}>
      <div className={classes.orderInfo}>
        {order.orderStatus === OrderStatusCode.DELIVERED ? (
          <p className={classes.orderDate}>배송일: {order.deliveredAt}</p>
        ) : (
          <p className={classes.orderDate}>주문시각: {orderDate}</p>
        )}
        <p className={classes.orderAmount}>{formatNumber(order.grandTotal + issuesTotalAmount)}원</p>
        {storeIssues && storeIssues.length > 0 && (
          <div className={classes.issues}>
            <p>※ {storeIssues.length}건의 특이사항 있습니다.</p>
          </div>
        )}
        <div className={classes.orderStateBox}>
          <p>
            {order.products.length}가지 물품 - 총 수량{formatNumber(productAmount)}개
          </p>
          <b className={classes[orderStatusEn[order.orderStatus]]}>{orderStatusKr[order.orderStatus]}</b>
        </div>
        {/* <Button>장바구니에 담기</Button> */}
      </div>
      <RightOutlined />
    </div>
  );
};

export default OrderHistoryItem;
