import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { sleep } from 'src/lib/1/util';

const useSearchSortKey = (defaultSortKey?: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSortKey = searchParams.get('sort') ?? defaultSortKey;

  const onChangeSortKey = useCallback(
    (sortKey: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('sort', sortKey);
      setSearchParams(newSearchParams, { replace: true });
      sleep(100).then(() => window.scrollTo({ top: 0 }));
    },
    [searchParams, setSearchParams]
  );

  return {
    currentSortKey,
    onChangeSortKey,
  };
};

export default useSearchSortKey;
