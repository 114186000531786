import { FC, useCallback, useEffect, useState } from 'react';
import { GaEventType } from 'src/schema/schema-ga-event';
import { sortKeyMappings } from 'src/utils/algoliasearch-util';

import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

import useInifiniteAlgoliaSearch from 'src/hooks/useInfiniteAlgoliaSearch';
import useSearchSortKey from 'src/hooks/useSearchSortKey';

import DropdownBox from '../Common/DropdownBox/DropdownBox';
import SearchWithAutoComplete from '../organisms/SearchWithAutoComplete/SearchWithAutoComplete';
import InfiniteProductList from '../templates/InfiniteProductList/InfiniteProductList';
import classes from './SearchResult.module.scss';

interface SearchResultProps {
  keyword: string;
}

const SearchResult: FC<SearchResultProps> = ({ keyword }) => {
  const { currentSortKey, onChangeSortKey } = useSearchSortKey('판매량순');
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);
  const { hits, totalPages, totalHits, isLoading, isValidating, size, setSize } = useInifiniteAlgoliaSearch(
    keyword,
    undefined,
    currentSortKey
  );

  const showMore = useCallback(
    (size: number) => {
      setSize(size);
      sendGaEvent({
        name: GaEventType.PAGE_SHOW_MORE,
        eventParams: {
          title: 'search',
          query: keyword,
          goToPage: size,
        },
      });
    },
    [keyword, setSize]
  );

  useEffect(() => {
    if (showAutoComplete) {
      const htmlElement = document.documentElement;
      const currentScroll = htmlElement.scrollTop;
      htmlElement.classList.add('no-scroll');
      htmlElement.style.top = `-${currentScroll}px`;
    } else {
      const htmlElement = document.documentElement;
      const scrollY = htmlElement.style.top;
      htmlElement.classList.remove('no-scroll');
      htmlElement.style.top = 'auto';
      htmlElement.scrollTo({
        top: parseInt(scrollY || '0') * -1,
        behavior: 'instant' as any,
      });
    }

    return () => {
      const htmlElement = document.documentElement;
      htmlElement.classList.remove('no-scroll');
    };
  }, [showAutoComplete]);

  // 검색어 변경이 생기는 경우 페이지 이동으로 간주하여 스크롤을 맨 위로 올린다.
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [keyword]);

  return (
    <>
      <SearchWithAutoComplete
        showAutoComplete={showAutoComplete}
        setShowAutoComplete={setShowAutoComplete}
        defaultValue={keyword}
        autoFocus={false}
        replace={true}
      />
      {totalHits > 0 && (
        <div className={classes.searchOption}>
          <span>총 {totalHits}개</span>
          <DropdownBox
            values={Object.keys(sortKeyMappings)}
            currentValue={currentSortKey ?? Object.keys(sortKeyMappings)[0]}
            handleValue={onChangeSortKey}
          />
        </div>
      )}
      <div className={`${classes.marginTop} ${totalHits > 0 ? classes.showOption : ''}`} />
      <InfiniteProductList
        isLoading={isLoading}
        isValidating={isValidating}
        productIds={hits.flatMap((hit) => hit.objectID)}
        totalPages={totalPages}
        size={size}
        setSize={showMore}
      />
    </>
  );
};

export default SearchResult;
