import { FC } from 'react';
import { sortKeyMappings } from 'src/utils/algoliasearch-util';

import { selectUnhiddenProducts } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useInifiniteAlgoliaSearch from 'src/hooks/useInfiniteAlgoliaSearch';
import useSearchSortKey from 'src/hooks/useSearchSortKey';
import { useTitle } from 'src/hooks/useTitle';

import DropdownBox from '../Common/DropdownBox/DropdownBox';
import ToolBar from '../molecules/ToolBar/ToolBar';
import InfiniteProductList from '../templates/InfiniteProductList/InfiniteProductList';

interface ProductsForCategoryProps {
  category: string;
  title: string;
}

const ProductsForCategory: FC<ProductsForCategoryProps> = ({ category, title }) => {
  const unhiddenProducts = useAppSelector(selectUnhiddenProducts);
  const { currentSortKey, onChangeSortKey } = useSearchSortKey('판매량순');
  const { hits, totalPages, isLoading, isValidating, size, setSize } = useInifiniteAlgoliaSearch(
    category,
    ['categories'],
    currentSortKey
  );
  useTitle(`GU | ${title}`);

  return (
    <>
      <ToolBar
        title={title}
        backButton='left'
        rightChildren={
          <DropdownBox
            values={Object.keys(sortKeyMappings)}
            currentValue={currentSortKey ?? Object.keys(sortKeyMappings)[0]}
            handleValue={onChangeSortKey}
          />
        }
      />
      <InfiniteProductList
        isLoading={isLoading}
        isValidating={isValidating}
        productIds={category === 'custom' ? unhiddenProducts : hits.flatMap((hit) => hit.objectID)}
        totalPages={totalPages}
        size={size}
        setSize={setSize}
      />
    </>
  );
};

export default ProductsForCategory;
